
import { defineComponent } from "vue"
import "./assets/style.css"
import NotificationToast from "@/components/notifications/notification-toast.vue"

export default defineComponent({
  name: "App",
  components: {
    NotificationToast
  }
})
