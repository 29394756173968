const changeMetaTags = (meta: any) => {
  document.title = `${meta.title}`

  // og:title
  let ogTitleTag = document.querySelector('meta[property="og:title"]')
  if (!ogTitleTag) {
    ogTitleTag = document.createElement("meta")
    ogTitleTag.setAttribute("property", "og:title")
    document.head.appendChild(ogTitleTag)
  }
  ogTitleTag.setAttribute("content", meta["og:title"])

  // description
  let metaDescription = document.querySelector('meta[name="description"]')
  if (!metaDescription) {
    metaDescription = document.createElement("meta")
    metaDescription.setAttribute("name", "description")
    document.head.appendChild(metaDescription)
  }
  metaDescription.setAttribute("content", meta.description)

  // og:description
  let ogDescriptionTag = document.querySelector(
    'meta[property="og:description"]'
  )
  if (!ogDescriptionTag) {
    ogDescriptionTag = document.createElement("meta")
    ogDescriptionTag.setAttribute("property", "og:description")
    document.head.appendChild(ogDescriptionTag)
  }
  ogDescriptionTag.setAttribute("content", meta["og:description"])

  // og:image
  let ogImageTag = document.querySelector('meta[property="og:image"]')
  if (!ogImageTag) {
    ogImageTag = document.createElement("meta")
    ogImageTag.setAttribute("property", "og:image")
    document.head.appendChild(ogImageTag)
  }
  ogImageTag.setAttribute("content", meta["og:image"])

  // og:type
  let ogTypeTag = document.querySelector('meta[property="og:type"]')
  if (!ogTypeTag) {
    ogTypeTag = document.createElement("meta")
    ogTypeTag.setAttribute("property", "og:type")
    document.getElementsByTagName("head")[0].appendChild(ogTypeTag)
  }
  ogTypeTag.setAttribute("content", meta["og:type"])

  // og:url
  let ogUrlTag = document.querySelector('meta[property="og:url"]')
  if (!ogUrlTag) {
    ogUrlTag = document.createElement("meta")
    ogUrlTag.setAttribute("property", "og:url")
    document.getElementsByTagName("head")[0].appendChild(ogUrlTag)
  }
  ogUrlTag.setAttribute("content", window.location.href)

  // Twitter ==========
  let twitterCard = document.querySelector('meta[name="twitter:card"]')
  if (!twitterCard) {
    twitterCard = document.createElement("meta")
    twitterCard.setAttribute("name", "twitter:card")
    document.getElementsByTagName("head")[0].appendChild(twitterCard)
  }
  twitterCard.setAttribute("content", "summary_large_image")

  let twitterTitle = document.querySelector('meta[name="twitter:title"]')
  if (!twitterTitle) {
    twitterTitle = document.createElement("meta")
    twitterTitle.setAttribute("name", "twitter:title")
    document.getElementsByTagName("head")[0].appendChild(twitterTitle)
  }
  twitterTitle.setAttribute("content", meta["og:title"])

  let twitterDescription = document.querySelector(
    'meta[name="twitter:description"]'
  )
  if (!twitterDescription) {
    twitterDescription = document.createElement("meta")
    twitterDescription.setAttribute("name", "twitter:description")
    document.getElementsByTagName("head")[0].appendChild(twitterDescription)
  }
  twitterDescription.setAttribute("content", meta["og:description"])

  let twitterImage = document.querySelector('meta[name="twitter:image"]')
  if (!twitterImage) {
    twitterImage = document.createElement("meta")
    twitterImage.setAttribute("name", "twitter:image")
    document.getElementsByTagName("head")[0].appendChild(twitterImage)
  }
  twitterImage.setAttribute("content", meta["og:image"])

  let twitterUrl = document.querySelector('meta[name="twitter:url"]')
  if (!twitterUrl) {
    twitterUrl = document.createElement("meta")
    twitterUrl.setAttribute("name", "twitter:url")
    document.getElementsByTagName("head")[0].appendChild(twitterUrl)
  }
  twitterUrl.setAttribute("content", window.location.href)

  //   robots
  let robotsTag = document.querySelector('meta[name="robots"]')
  if (meta.robots && !robotsTag) {
    robotsTag = document.createElement("meta")
    robotsTag.setAttribute("name", "robots")
    robotsTag.setAttribute("content", "index, follow")
    document.head.appendChild(robotsTag)
  } else if (!meta.robots && robotsTag) {
    document.head.removeChild(robotsTag)
  }

  // googlebot
  let googlebotTag = document.querySelector('meta[name="googlebot"]')
  if (meta.googlebot && !googlebotTag) {
    googlebotTag = document.createElement("meta")
    googlebotTag.setAttribute("name", "googlebot")
    googlebotTag.setAttribute("content", "index, follow")
    document.head.appendChild(googlebotTag)
  } else if (!meta.robots && googlebotTag) {
    document.head.removeChild(googlebotTag)
  }

  // mobile meta tag
  let viewPortTag = document.querySelector('meta[name="viewport"]')
  if (meta.isResponsive) {
    if (!viewPortTag) {
      viewPortTag = document.createElement("meta")
      viewPortTag.setAttribute("name", "viewport")
      viewPortTag.setAttribute(
        "content",
        "width=device-width,initial-scale=1.0"
      )
      document.head.appendChild(viewPortTag)
    }
  } else {
    if (viewPortTag) viewPortTag.remove()
  }

  // canonical
  if (meta.canonical) {
    let canonicalTag = document.createElement("link")
    canonicalTag.setAttribute("rel", "canonical")
    canonicalTag.setAttribute("href", "https://smartlnks.com")
    document.head.appendChild(canonicalTag)
  } else {
    const links = document.head.getElementsByTagName("link")
    if (links && links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        if (links[i].getAttribute("rel") === "canonical") {
          if (links[i] && links[i].parentNode) {
            links[i].parentNode?.removeChild(links[i])
          }
        }
      }
    }
  }
}

export { changeMetaTags }
