import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import PrimeVue from "primevue/config/"
import { createPinia } from "pinia"

// apex charts
import VueApexCharts from "vue3-apexcharts"

// Vue Number Animation
import VueNumber from "vue-number-animation"

// Notiwind-Ts
import Notifications from "notiwind"

// css
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "primevue/resources/themes/tailwind-light/theme.css"

// PrimeVue components
import Menubar from "primevue/menubar"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import Listbox from "primevue/listbox"
import VirtualScroller from "primevue/virtualscroller"
import ProgressSpinner from "primevue/progressspinner"
import Skeleton from "primevue/skeleton"
import SplitButton from "primevue/splitbutton"
import Toast from "primevue/toast"
import ToastService from "primevue/toastservice"
import Dropdown from "primevue/dropdown"
import Menu from "primevue/menu"
import Dialog from "primevue/dialog"
import Ripple from "primevue/ripple"
import Textarea from "primevue/textarea"
import Message from "primevue/message"
import ConfirmationService from "primevue/confirmationservice"
import ConfirmPopup from "primevue/confirmpopup"
import ConfirmDialog from "primevue/confirmdialog"
import ToggleButton from "primevue/togglebutton"
import DialogService from "primevue/dialogservice"
import DynamicDialog from "primevue/dynamicdialog"
import Splitter from "primevue/splitter"
import SplitterPanel from "primevue/splitterpanel"
import Column from "primevue/column"
import Rating from "primevue/rating"
import DataTable from "primevue/datatable"
import Tag from "primevue/tag"
import Row from "primevue/row"
import Calendar from "primevue/calendar"
import Slider from "primevue/slider"
import TriStateCheckbox from "primevue/tristatecheckbox"
import Tooltip from "primevue/tooltip"
import Toolbar from "primevue/toolbar"
import FileUpload from "primevue/fileupload"
import InputNumber from "primevue/inputnumber"
import Checkbox from "primevue/checkbox"
import ContextMenu from "primevue/contextmenu"
import Paginator from "primevue/paginator"
import Sidebar from "primevue/sidebar"
import Breadcrumb from "primevue/breadcrumb"
import MultiSelect from "primevue/multiselect"
import TieredMenu from "primevue/tieredmenu"
import Timeline from "primevue/timeline"
import Card from "primevue/card"
import TabMenu from "primevue/tabmenu"
import Avatar from "primevue/avatar"
import AvatarGroup from "primevue/avatargroup"
import SelectButton from "primevue/selectbutton"
import PanelMenu from "primevue/panelmenu"
import Badge from "primevue/badge"
import BadgeDirective from "primevue/badgedirective"
import OverlayPanel from "primevue/overlaypanel"
import Chip from "primevue/chip"
import ColorPicker from "primevue/colorpicker"
import Password from "primevue/password"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(PrimeVue, { ripple: true })
app.use(ToastService)
app.use(ConfirmationService)
app.use(DialogService)
app.use(VueApexCharts)
app.use(VueNumber)
app.use(Notifications)

// ==========================================================================
// SENTRY
// ==========================================================================
// import * as Sentry from "@sentry/vue"
// import { BrowserTracing } from "@sentry/tracing"

// Sentry.init({
//   app,
//   dsn: "https://09186826f506442a9cca9811e9ee0285@o4504595640352768.ingest.sentry.io/4504595645136896",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ["localhost", "smartlnks.com", /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

// ==========================================================================
// META TAGS
// ==========================================================================

// import { createMetaManager } from "vue-meta"

// Define the default configuration object
// const defaultConfig: any = {
//   title: "My Website",
//   meta: [
//     {
//       name: "description",
//       content: "My website description"
//     }
//   ]
// }

// Define the resolver function
// function myResolver(newTag: any, currentTags: any) {
//   if (newTag.tag === "meta" && newTag.key === "description") {
//     return {
//       ...newTag,
//       content: `${newTag.content} - My Smartlnks`
//     }
//   }

//   return newTag
// }

// Pass in the default configuration object and resolver function to createMetaManager
// app.use(createMetaManager(defaultConfig, myResolver))

// ==========================================================================
// VUE AWESOME SWIPER
// ==========================================================================
import VueAwesomeSwiper from "vue-awesome-swiper"

import "swiper/swiper-bundle.css"

app.use(VueAwesomeSwiper)

// ==========================================================================
// VUE LOTTIE
// ==========================================================================
import Vue3Lottie from "vue3-lottie"
import "vue3-lottie/dist/style.css"

app.use(Vue3Lottie)

app.mount("#app")
app.directive("ripple", Ripple)
app.directive("tooltip", Tooltip)
app.directive("badge", BadgeDirective)

import VueLazyLoad from "vue3-lazyload"
app.use(VueLazyLoad)

// google login
import GoogleSignInPlugin from "vue3-google-signin"
app.use(GoogleSignInPlugin, {
  clientId:
    "855236921220-cfagpt7lpbc2foac7f5ndmr3ms4os40o.apps.googleusercontent.com"
})

import VueGoogleCharts from "vue-google-charts"
app.use(VueGoogleCharts)

// google tag manager
import { createGtm } from "@gtm-support/vue-gtm"
app.use(
  createGtm({
    id: "GTM-582ZHZG",
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "3837d8g37d", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true // Whether or not display console logs debugs (optional)
  })
)

// VueSocialSharing
import VueSocialSharing from "vue-social-sharing"
app.use(VueSocialSharing)

// auto animate
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
app.use(autoAnimatePlugin)

// auto resize text
import VueResizeText from "vue-resize-text"
app.use(VueResizeText)

// notification
import { notify } from "notiwind"
app.component("notify", notify)

app.component("Menubar", Menubar)
app.component("InputText", InputText)
app.component("Button", Button)
app.component("VirtualScroller", VirtualScroller)
app.component("Listbox", Listbox)
app.component("ProgressSpinner", ProgressSpinner)
app.component("Skeleton", Skeleton)
app.component("SplitButton", SplitButton)
app.component("Toast", Toast)
app.component("Dropdown", Dropdown)
app.component("Menu", Menu)
app.component("Dialog", Dialog)
app.component("Textarea", Textarea)
app.component("Message", Message)
app.component("ConfirmPopup", ConfirmPopup)
app.component("ConfirmDialog", ConfirmDialog)
app.component("ToggleButton", ToggleButton)
app.component("DynamicDialog", DynamicDialog)
app.component("Splitter", Splitter)
app.component("SplitterPanel", SplitterPanel)
app.component("Column", Column)
app.component("Rating", Rating)
app.component("DataTable", DataTable)
app.component("Tag", Tag)
app.component("Row", Row)
app.component("Calendar", Calendar)
app.component("Slider", Slider)
app.component("TriStateCheckbox", TriStateCheckbox)
app.component("Toolbar", Toolbar)
app.component("FileUpload", FileUpload)
app.component("InputNumber", InputNumber)
app.component("Checkbox", Checkbox)
app.component("ContextMenu", ContextMenu)
app.component("Paginator", Paginator)
app.component("Sidebar", Sidebar)
app.component("Breadcrumb", Breadcrumb)
app.component("MultiSelect", MultiSelect)
app.component("TieredMenu", TieredMenu)
app.component("Timeline", Timeline)
app.component("Card", Card)
app.component("TabMenu", TabMenu)
app.component("Avatar", Avatar)
app.component("AvatarGroup", AvatarGroup)
app.component("SelectButton", SelectButton)
app.component("PanelMenu", PanelMenu)
app.component("Badge", Badge)
app.component("OverlayPanel", OverlayPanel)
app.component("Chip", Chip)
app.component("ColorPicker", ColorPicker)
app.component("Password", Password)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)

import Inplace from "primevue/inplace"
app.component("Inplace", Inplace)

import AutoComplete from "primevue/autocomplete"
app.component("AutoComplete", AutoComplete)

import InlineMessage from "primevue/inlinemessage"
app.component("InlineMessage", InlineMessage)

import Chips from "primevue/chips"
app.component("Chips", Chips)

import ProgressBar from "primevue/progressbar"
app.component("ProgressBar", ProgressBar)

import InputSwitch from "primevue/inputswitch"
app.component("InputSwitch", InputSwitch)

import RadioButton from "primevue/radiobutton"
app.component("RadioButton", RadioButton)

import BlockUI from "primevue/blockui"
app.component("BlockUI", BlockUI)

import Divider from "primevue/divider"
app.component("Divider", Divider)

import PickList from "primevue/picklist"
app.component("PickList", PickList)

import Steps from "primevue/steps"
app.component("Steps", Steps)

// Override the default console methods
console.log = function () {}
console.info = function () {}
console.warn = function () {}
console.error = function () {}
