import { defineStore } from "pinia"
import { ref } from "vue"
import { EncryptStorage } from "encrypt-storage"

export const useAuthenticationStore = defineStore("authentication", () => {
  const encryptStorage = new EncryptStorage("wounded-mukhtar-1")

  const header = ref<{}>({
    "Content-Type": "application/json",
    Authorization: "Token " + encryptStorage.getItem("token") || null
  })

  const mediaHeader = ref<{}>({
    "Content-Type": "multipart/form-data",
    Authorization: "Token " + encryptStorage.getItem("token") || null
  })

  const isAuthenticted = ref<boolean>(
    encryptStorage.getItem("token") ? true : false
  )

  const saveToken = (token: any) => {
    console.log("==> saving token")
    encryptStorage.setItem("token", token)
    isAuthenticted.value = true
    updateHeaders()
  }

  const updateHeaders = () => {
    header.value = {
      "Content-Type": "application/json",
      Authorization: "Token " + encryptStorage.getItem("token") || null
    }

    mediaHeader.value = {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + encryptStorage.getItem("token") || null
    }
  }

  const loginInfo = ref({
    user: encryptStorage.getItem("user") || null
  })

  const saveUserInfo = (data: any) => {
    console.log("==> saving user info")
    encryptStorage.setItem("user", JSON.stringify(data))

    updateUserInfo()
  }

  const updateUserInfo = () => {
    loginInfo.value.user = encryptStorage.getItem("user") || null
  }

  const clearLocalStorage = () => {
    encryptStorage.removeItem("token")
    encryptStorage.removeItem("user")
    updateHeaders()
    isAuthenticted.value = false
  }

  return {
    header,
    mediaHeader,
    loginInfo,
    isAuthenticted,
    clearLocalStorage,
    saveToken,
    saveUserInfo,
    updateUserInfo
  }
})
