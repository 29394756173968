<template>
	<svg
		class="w-6 h-6 text-red-500"
		fill="none"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1.6"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M12 8v4"></path>
		<path d="M12 16.02V16"></path>
		<path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
	</svg>
</template>
