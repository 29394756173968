import { storeToRefs } from "pinia"
import { useAuthenticationStore } from "@/stores/authenticationStore"
import { changeMetaTags } from "@/utils"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "Open in Apps Made Easy with Smartlinks - The Best App Opener",
      description:
        "The all-in-one link management solution! Organize, schedule, openinapp, and analyze clicks with ease. Target specific devices & regions, set redirects, & more",
      "og:title": `Open in Apps Made Easy with Smartlinks - The Best App Opener`,
      "og:description":
        "The all-in-one link management solution! Organize, schedule, openinapp, and analyze clicks with ease. Target specific devices & regions, set redirects, & more",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/smartlnks-index.png",
      "og:type": "website",
      keywords: `smartlinks, smartlnks, link management, native apps, appopener, app opener, open in app, openinapp, open in youtube, open in mobile, bitly, app link, open app, open the app, online, scheduling, password protection, clicks limit, geo-location, device OS, link optimization, user-friendly, open in app, appopener`,
      robots: true,
      googlebot: true,
      isResponsive: true,
      canonical: true
    }
  },
  {
    path: "/GetStarted/",
    name: "get-started",
    component: () => import("@/views/GetStarted.vue"),
    meta: {
      title:
        "Join the Link Management Revolution - Secure Access to Your Account with Smartlnks",
      description:
        "Join the millions of professionals who trust Smartlnks to manage their links. Sign up today for advanced tracking and insights on your link performance.",
      "og:title": `Join the Link Management Revolution - Secure Access to Your Account with Smartlnks`,
      "og:description":
        "Join the millions of professionals who trust Smartlnks to manage their links. Sign up today for advanced tracking and insights on your link performance.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `sign in, sign up, register, Smartlinks, smartlnks, account access, online, link management, scheduling, password protection, clicks limit, geo-location`,
      robots: true,
      googlebot: true,
      isResponsive: true,
      canonical: true
    }
  },
  {
    path: "/blog/",
    name: "blog",
    component: () => import("@/views/BlogView.vue"),
    meta: {
      title:
        "Smartlinks Blog - Latest insights and tips on link management and analytics",
      description:
        "Discover the power of Smartlinks for your link management needs. Boost your marketing with advanced analytics and targeting capabilities. Learn more now.",
      "og:title": `Smartlinks Blog - Latest insights and tips on link management and analytics`,
      "og:description":
        "Discover the power of Smartlinks for your link management needs. Boost your marketing with advanced analytics and targeting capabilities. Learn more now.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: ``,
      robots: true,
      googlebot: true,
      isResponsive: true,
      canonical: true
    }
  },
  {
    path: "/blog/:id",
    name: "BlogPost",
    component: () => import("@/components/blog/BlogPost.vue"),
    meta: {
      title:
        "Smartlinks Blog - Latest insights and tips on link management and analytics",
      description:
        "Discover the power of Smartlinks for your link management needs. Boost your marketing with advanced analytics and targeting capabilities. Learn more now.",
      "og:title": `Smartlinks Blog - Latest insights and tips on link management and analytics`,
      "og:description":
        "Discover the power of Smartlinks for your link management needs. Boost your marketing with advanced analytics and targeting capabilities. Learn more now.",
      "og:type": "website",
      robots: true,
      googlebot: true,
      isResponsive: true,
      canonical: true
    }
  },
  {
    path: "/dashboard/",
    name: "dashboard",
    component: () => import("@/views/OverviewView.vue"),
    meta: {
      requiresAuth: true,
      title:
        "Smartlnks | The Ultimate Link Management Platform for Native App Experience",

      description:
        "Welcome to Smartlnks, the premier link management platform that helps you open your links in native apps and boost conversions. Experience seamless integration with your apps, track clicks and view analytics all in one place. Sign up now!",
      "og:title": `Smartlnks | The Ultimate Link Management Platform for Native App Experience`,
      "og:description":
        "Welcome to Smartlnks, the premier link management platform that helps you open your links in native apps and boost conversions. Experience seamless integration with your apps, track clicks and view analytics all in one place. Sign up now!",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `smartlinks, smartlnks, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/overview",
    name: "overview",
    component: () => import("@/views/OverviewView.vue"),
    meta: {
      requiresAuth: true,
      title:
        "Welcome to the Smartlnks Dashboard - Advanced Link Management at Your Fingertips",
      description:
        "The Smartlnks Dashboard is your one-stop destination for all your advanced link management needs. From scheduling links to setting clicks limits and redirecting users based on their location and device OS, you have all the tools you need to optimize your link strategy.",
      "og:title": `Smartlnks Dashboard - Advanced Link Management at Your Fingertips`,
      "og:description":
        "Welcome to the Smartlnks Dashboard, your one-stop destination for all your advanced link management needs. From scheduling links to setting clicks limits and redirecting users based on their location and device OS, you have all the tools you need to optimize your link strategy.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `smartlinks, smartlnks, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/collections",
    name: "collections",
    component: () => import("@/views/CollectionsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Organize Your Links with Smartlnks Collections",
      description:
        "Transform your link management with Smartlnks Collections. Easily group your links, add descriptive notes, and categorize for quick access and advanced tracking.",
      "og:title": `Organize Your Links with Smartlnks Collections`,
      "og:description":
        "Transform your link management with Smartlnks Collections. Easily group your links, add descriptive notes, and categorize for quick access and advanced tracking.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `smartlinks, smartlnks, collections, link grouping, link tracking, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/calendar",
    name: "calendar",
    component: () => import("@/views/CalendarView.vue"),
    meta: {
      requiresAuth: true,
      title: "Schedule and Track Your Links with Smartlnks Calendar",
      description:
        "Maximize your link performance with Smartlnks Calendar. Schedule your links, see your link performance in real-time, and track your success.",
      "og:title": `Schedule and Track Your Links with Smartlnks Calendar`,
      "og:description":
        "Maximize your link performance with Smartlnks Calendar. Schedule your links, see your link performance in real-time, and track your success.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `smartlinks, smartlnks, calendar, link scheduling, link performance, link tracking, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/activities",
    name: "activities",
    component: () => import("@/views/ActivitiesView.vue"),
    meta: {
      requiresAuth: true,
      title: "Track Your Activity on Smartlnks with Activity Logs",
      description:
        "Stay on top of your link management with Smartlnks Activity Logs. Easily view your create, read, update, and delete operations in one centralized location.",
      "og:title": `Track Your Activity on Smartlnks with Activity Logs`,
      "og:description":
        "Stay on top of your link management with Smartlnks Activity Logs. Easily view your create, read, update, and delete operations in one centralized location.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `activity logs, CRUD operations, centralized location, smartlinks, smartlnks, calendar, link scheduling, link performance, link tracking, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/compare",
    name: "compare",
    component: () => import("@/views/CompareView.vue"),
    meta: {
      requiresAuth: true,
      title: "",
      description: "",
      "og:title": ``,
      "og:description": "",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: ``,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/dashboard/settings",
    name: "settings",
    redirect: "/dashboard/settings/tab=my-details"
  },
  {
    path: "/dashboard/settings/tab=:id",
    name: "settings-tab",
    component: () => import("@/views/SettingsView.vue"),
    meta: {
      requiresAuth: true,
      title: "Settings | Smartlnks",
      description:
        "Join the millions of professionals who trust Smartlnks to manage their links. Sign up today for advanced tracking and insights on your link performance.",
      "og:title": `Settings | Smartlnks`,
      "og:description":
        "Join the millions of professionals who trust Smartlnks to manage their links. Sign up today for advanced tracking and insights on your link performance.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/dashboard-features.webp",
      "og:type": "website",
      keywords: `account customization, preferences, optimization, smartlinks, smartlnks, calendar, link scheduling, link performance, link tracking, dashboard, link management, analytics, tracking, appopener, open in app`,
      robots: true,
      googlebot: true,
      isResponsive: false,
      canonical: false
    }
  },
  {
    path: "/collection/:end_point",
    name: "collection-share-template",
    component: () => import("@/views/CollectionShareView.vue"),
    props: true,
    meta: {
      title: "Share Collection | Smartlnks",
      description:
        "Share collections of Smartlnks with friends and followers! Easily group related Smartlnks and share with anyone you want.",
      "og:title": "Share Collection | Smartlnks",
      "og:description":
        "Share collections of Smartlnks with friends and followers! Easily group related Smartlnks and share with anyone you want.",
      "og:image": "",
      "og:type": "website",
      isResponsive: true,
      canonical: false
    }
  },
  // {
  //   path: "/socket",
  //   component: () => import("@/views/Socket.vue")
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/404PageView.vue"),
    meta: {
      title: "404 Page Not Found | Smartlnks",
      description:
        "404 Error: Page not found. But don't worry, you're not the only one who's confused - we are too.",
      "og:title": `404 Page Not Found | Smartlnks`,
      "og:description":
        "404 Error: Page not found. But don't worry, you're not the only one who's confused - we are too.",
      "og:image":
        "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/404-2.png",
      "og:type": "website",
      keywords: ``,
      robots: false,
      googlebot: false,
      isResponsive: true,
      canonical: false
    }
  }

  // Transform your link experience with Smartlnks and openinapp. Effortlessly open links in native apps, schedule links, secure them with passwords, set clicks limits, and redirect users based on their location and device OS for optimal results. Upgrade your link strategy today with Smartlnks, the ultimate app link opener and manager.
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import("@/views/HomeView.vue"),
  //   meta: {
  //     title:
  //       "Effortlessly Open Links in Native Apps with Smartlnks - Advanced Link Management",
  //     description:
  //       "Transform your link experience with Smartlnks. Effortlessly open links in native apps, schedule links, secure them with passwords, set clicks limits, and redirect users based on their location and device OS for optimal results. Upgrade your link strategy today with Smartlnks.",
  //     "og:title": `Revolutionize Your Link Strategy with Smartlnks - Open Links in Native Apps`,
  //     "og:description":
  //       "Elevate your link experience with Smartlnks. Open links in native apps with ease, schedule links, secure them with passwords, set clicks limits, and redirect users based on their location and device OS for optimal results. Upgrade your link strategy today with Smartlnks.",
  //     "og:image":
  //       "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/smartlnks-ig-image.webp",
  //     "og:type": "website",
  //     keywords: `smartlinks, smartlnks, link management, native apps, appopener, app opener, open in app, openinapp, open in youtube, open in mobile, bitly, app link, open app, open the app, online, scheduling, password protection, clicks limit, geo-location, device OS, link optimization, user-friendly, open in app, appopener`,
  //     robots: true,
  //     googlebot: true,
  //     isResponsive: true,
  //     canonical: true
  //   }
  // },

  // {
  //   path: "/sign-in/",
  //   name: "sign-in",
  //   component: () => import("@/views/SignInView.vue"),
  //   meta: {
  //     title: "Sign In - Secure Access to Your Account with Smartlnks",
  //     description:
  //       "Sign in to your Smartlnks account to access advanced link management features such as scheduling, password protection, clicks limit, and geo-location based redirection.",
  //     "og:title": `Sign In to Your Smartlnks Account - Advanced Link Management Features`,
  //     "og:description":
  //       "Sign in to your Smartlnks account to access advanced link management features such as scheduling, password protection, clicks limit, and geo-location based redirection.",
  //     "og:image": "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/logo.png",
  //     keywords: `sign in, Smartlinks, smartlnks, account access, link management, scheduling, password protection, clicks limit, geo-location`,
  //     robots: true,
  //     googlebot: true
  //   }
  // },
  // {
  //   path: "/debug",
  //   name: "debug",
  //   component: HomeView
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: false
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const useAuthStore = useAuthenticationStore()
    const { isAuthenticted } = storeToRefs(useAuthStore)
    if (!isAuthenticted.value) {
      next({
        path: "/GetStarted/",
        query: { redirect: to.fullPath }
      })
    }
  }
  changeMetaTags(to.meta)
  next()
})

export default router
